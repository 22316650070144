import ItemLink from './linkItem'
import MainItem from './mainItem'
import Modal from './modal';


const Item = ({ itemsData, handleItemClick, selectedItem, commonData, handleCloseModal, handleService, main_list, id }) => {

    return (
        <div className="items__wrapper">
            <div className='items'>
            {itemsData.map(item => (
                !item.as_category && main_list ? (
                    <ItemLink
                        key={Math.random()}
                        id={item.pk}
                        name={item.name}
                        descr={item.description}
                        img={`https://100godzin.pl${item.image}`}
                    />
                ) : (
                    <MainItem
                        key={Math.random()}
                        id={item.id}
                        name={item.name}
                        descr={item.description}
                        img={`https://100godzin.pl${item.image}`}
                        text={item.text}
                        onClick={() => handleItemClick({
                            name: item.name,
                            img: `https://100godzin.pl${item.image}`,
                            descr: item.description,
                            text: item.text,
                            link: item.link,
                            is_service: item.is_service ? item.is_service : false
                        })}
                    />
                )
            ))}
            {selectedItem && (
                    <Modal
                        name={selectedItem.name}
                        img={selectedItem.img}
                        descr={selectedItem.descr}
                        text={selectedItem.text}
                        close_text = {commonData.close}
                        more_text = {commonData.more}
                        onClose={handleCloseModal}
                        onMore={selectedItem.is_service? handleService : (
                            () => {
                                window.location.href = selectedItem.link;
                                 }
                            ) }
                    />
                 )
            }
            </div>
        </div>

    )
}

export default Item
