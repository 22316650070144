import './style.css'
import RunningText from './runningText';
import enIcon from '../img/en.png'
import uaIcon from '../img/ua.png'


const Header = ({ text_button, text_run, toggleLanguage, language }) => {


    return <>
        <div className="header__wrapper">
            <div className="header">
                <div className="header__marquee-container">
                    <div className="header__marquee-anim">
                        <RunningText text={text_run} />
                    </div>
                </div>
                <div className="header__lang" onClick={toggleLanguage}>
                    {language === 'en' ? <img src={enIcon} alt="English" /> : <img src={uaIcon} alt="Ukrainian" />}
                    {text_button}</div>
            </div>
        </div>
    </>


}

export default Header
