import { Link } from 'react-router-dom';
import './style.css'


const LinkItem = ({ id, name, descr, img }) => {
    return (
        <Link className="item" to={`/items/${id}`}>
            <div className="item__img">
                <img src={img} alt={name} />
            </div>
            <div className="item__name">{name}</div>
            <div className="item__descr">{descr}</div>
        </Link>
    )
}

export default LinkItem
