
import './style.css'

const Error = () => {
    return <div className="wrapper">

    <div className="error">
        <div className="error__wrapper">
            <div className='error__text-small'>OOPS...</div>
            <div className='error__text-main'>404</div>
            <div className='error__text-descr'> page <span> not found</span> </div>
        </div>
    </div>
    </div>
    
}

export default Error