
const RunningText = ({ text }) => {
    return (
        <>
            {text.split('').map((char, index) => (
                char === ' ' 
                ? <span key={index} style={{ marginLeft: 5 }}></span> 
                : <span key={index}>{char}</span>
            ))}
        </>
    );
}

export default RunningText;

