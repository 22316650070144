import Item from './item'
import React, { useState, useEffect } from 'react';
import Header from './header'
import HeaderPrev from './headerPrev'
import './style.css'


const ItemsList = ({ main_list, handleService, query, toggleLanguage, language }) => {
    const [selectedItem, setselectedItem] = useState(null);
    const [itemsData, setItemsData] = useState([]);
    const [commonData, setCommonData] = useState([]);
    const [loading, setLoading] = useState(true);
    const handleItemClick = (item) => { setselectedItem(item) };
    const handleCloseModal = () => { setselectedItem(null) };


    useEffect(() => {
        if (query) {
            setLoading(true);
            fetch(query)
                .then(response => response.json())
                .then(data => {
                    setCommonData(data.common);
                    setItemsData(data.data);
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Error:', error);
                    setLoading(false);
                });
        }
    }, [query]);

    return (<>
        {loading ? (
            <div className='wrapper'>
                <div className="loader"></div>
            </div>
        ) : (
            <div className='wrapper'>
                {
                    main_list
                        ? <Header text_button={commonData.title} text_run={String(commonData.text)} toggleLanguage={toggleLanguage} language={language} />
                        : <HeaderPrev text_button={commonData.back} text_run={String(commonData.text)} />
                }
                <Item
                    itemsData={itemsData}
                    handleItemClick={handleItemClick}
                    selectedItem={selectedItem}
                    commonData={commonData}
                    handleCloseModal={handleCloseModal}
                    handleService={handleService}
                    main_list={main_list}
                />
         
            </div>


        )}


    </>
    )
}

export default ItemsList