import React, { useState } from 'react';
import AvatarEditor from 'react-avatar-editor';

const AvatarUpload = ({ onImageChange }) => {
    const [image, setImage] = useState(null);
    const [scale, setScale] = useState(1);
    const [editor, setEditor] = useState(null);
    const [fileName, setFileName] = useState('Файл не выбран');

    const handleImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0]; // Получаем файл напрямую
            const imageUrl = URL.createObjectURL(file); // Создаем URL для предпросмотра
            setImage(imageUrl);
            onImageChange(file); // Передаем файл обратно в Download
            setFileName(file.name);
        } else {
            setFileName('Файл не выбран');
            setImage(null);
            onImageChange(null); // Если файл не выбран, сбрасываем изображение в Download
        }
    };

    const handleScaleChange = (event) => {
        setScale(parseFloat(event.target.value));
    };

    return (
        <div className="avatar-upload">
            <div className='form__grup'>
                <input
                    type="file"
                    id="fileUpload"
                    className='form__logo-input'
                    accept="image/*"
                    onChange={handleImageChange}
                />
                <div className='form__grup-label__wrapper'>
                    <label htmlFor="fileUpload" className="form__logo-label">
                        Выберите файл
                    </label>
                    <div className='form__grup-nameImg'>{fileName}</div>
                </div>
            </div>
            {image && (
                <div className='logo__edit'>
                    <AvatarEditor
                        ref={setEditor}
                        image={image}
                        width={300}
                        height={300}
                        border={0}
                        borderRadius={150}
                        color={[0, 0, 0, 0.6]}
                        scale={scale}
                        rotate={0}
                        style={{ overflow: 'hidden' }}
                    />
                    <div className='logo__slider'>
                        <input
                            id="scale"
                            type="range"
                            min="1"
                            max="10"
                            step="0.1"
                            value={scale}
                            onChange={handleScaleChange}
                            className="slider"
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default AvatarUpload;