import './style.css'


const MainItem = ({ id, name, descr, img, onClick }) => {
    return (
        <div className="item" onClick={() => onClick(id)} >
         
          

            <div className="item__img">
                <img src={img} alt={name} />
            </div>
            <div className="item__name">{name}</div>
            <div className="item__descr">{descr}</div>

        
        </div>
    )
}

export default MainItem
